<template>
  <v-toolbar flat class="mb-4">
    <v-toolbar-title>
      {{ $t("silos.title") }}
      <span></span>
    </v-toolbar-title>
    <v-spacer></v-spacer>
    <div>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn
            class="mx-2"
            color="accent"
            fab
            small
            router
            to="/silos"
            v-on="on"
          >
            <v-icon dark>mdi-silo</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("silos.title") }}</span>
      </v-tooltip>

      <v-tooltip bottom>
        <template v-slot:activator="{ on }" v-if="permitted('Silos.Create')">
          <v-btn
            class="mx-2"
            fab
            small
            color="primary"
            router
            to="/silos/create"
            v-on="on"
          >
            <v-icon dark>mdi-plus</v-icon>
          </v-btn>
        </template>
        <span>{{ $t("silos.create") }}</span>
      </v-tooltip>
    </div>
  </v-toolbar>
</template>

<script>
export default {
  name: "SiloToolbar",
};
</script>